import * as React from "react"
import PageLayout from "../../components/pageLayout"
import {Button, Col, Form, Input, Row,  Select,  Space, Table } from "antd"

const {Column} = Table 
const {Option} = Select
const SearchInventoryPage = () => {

    return <PageLayout>
        <Space direction="vertical" style={{width:"100%"}}>
            <Form layout="vertical">
            <Row gutter={16}>                   
                <Col>
                    <Form.Item label="ID">
                        <Input placeholder="Id de artículo"></Input>
                    </Form.Item>                    
                </Col>
                <Col>
                    <Form.Item label="Modelo">
                        <Input placeholder="Modelo"></Input>
                    </Form.Item>                    
                </Col>
                <Col>
                    <Form.Item label="Talla">
                        <Input placeholder="Talla"></Input>
                    </Form.Item>                    
                </Col>
                <Col>
                    <Form.Item label="Sistema">
                        <Select defaultValue="app" style={{width:"150px"}}>                            
                            <Option value="app">App</Option>
                            <Option value="golden">Apartados</Option>
                            <Option value="conceptos">Conceptos</Option>
                            <Option value="kiosko">Kiosko</Option>
                        </Select>                       
                    </Form.Item>                    
                </Col>
                <Col>
                   <Form.Item label=" ">
                       <Button type="primary">Buscar</Button>
                   </Form.Item>
                </Col>                            
            </Row>
            </Form>
            <Table bordered>
                <Column title="Id"></Column>
                <Column title="Marca"></Column>
                <Column title="Modelo"></Column>
                <Column title="Talla"></Column>
                <Column title="Precio"></Column>
                <Column title="Inventario">
                    <Column title="Arco Norte"></Column>
                    <Column title="Ecatepec"></Column>
                    <Column title="Vallejo"></Column>
                    <Column title="Naucalpan"></Column>
                    <Column title="Olivar"></Column>
                    <Column title="Outlet">
                        <Column title="Arco"></Column>
                        <Column title="Ecatepec"></Column>
                    </Column>
                </Column>
            </Table>
        </Space>
    </PageLayout>
}

export default SearchInventoryPage